import { Flex } from '@components/Grid'
import styled from '@style'

const Item = styled(Flex)`
  width: 100%;
  height: auto;
  align-items: center;
  z-index: 10;
  padding: 1em;
`

export { Item }
