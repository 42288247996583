import { Link } from '@components/Link'
import styled, { themeGet } from '@style'

const Text = styled(Link)`
  font-size: ${themeGet('fontSizes.3')};
  font-family: ${themeGet('fonts.serif')};
  font-weight: ${themeGet('fontWeights.semibold')};
  color: ${themeGet('colors.blue.800')};
  text-decoration: none;
  padding: 0.5em;
  position: relative;
  word-break: initial;

  &:hover,
  &:focus {
    color: ${themeGet('colors.red.base')};
  }
`

export { Text }
