/* eslint-disable react/prop-types, no-underscore-dangle */
import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '@components/Layout'
import { Box } from '@components/Grid'
import { SEO } from '@components/SEO'
import { NewsletterSection } from '@components/NewsletterSection'
import { Hero } from '@components/Hero'
import { ExpertiseOverview } from '@components/ExpertiseList'

const ExpertisePage = ({ data: { content }, location }) => (
  <Layout>
    <SEO
      metadata={{
        title: content.seo.seoTitle,
        description: content.seo.seoDescription,
        pathname: location.pathname,
      }}
    />
    <Hero>
      <Hero.Heading content={content._rawHeading} textAlign="center" />
      <Hero.Text content={content._rawText} pl={0} m="0 auto" />
    </Hero>
    <Box mb={[6, 7]}>
      <ExpertiseOverview />
    </Box>
    <NewsletterSection />
  </Layout>
)

export const query = graphql`
  query ExpertisePage {
    content: sanityExpertisePageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHeading
      _rawText(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ExpertisePage
