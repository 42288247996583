/* eslint-disable no-await-in-loop */
import React from 'react'
import { Box, Br } from '@components/Grid'

import { useSpring, animated } from 'react-spring'
import styled, { themeGet } from '@style'

import { Item } from './Item'
import { Text } from './Text'

const AnimatedBlobb = styled(animated.div)`
  display: block;
  min-height: 60px;
  min-width: 60px;
  width: 20vw;
  height: 20vw;
  max-height: 110px;
  max-width: 110px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
  /* stylelint-disable */
  background: linear-gradient(
    210deg,
    ${themeGet('colors.blue.200')},
    ${themeGet('colors.blue.300')}
  );
  /* stylelint-enable */
`

const ExpertiseOverview = () => {
  const [isFirstHovered, setFirstHovered] = React.useState(false)
  const [isSecondHovered, setSecondHovered] = React.useState(false)
  const [isThirdHovered, setThirdHovered] = React.useState(false)
  const firstBlob = useSpring({
    config: { duration: 900 },
    from: {
      borderRadius: '76% 24% 35% 65% / 45% 70% 30% 55% ',
    },
    to: async next => {
      while (isFirstHovered) {
        await next({
          borderRadius: '60% 40% 54% 46% / 40% 65% 35% 60%',
        })
        await next({
          borderRadius: '78% 22% 63% 37% / 34% 34% 66% 66%',
        })
        await next({
          borderRadius: '51% 49% 63% 37% / 56% 42% 58% 44%',
        })
        await next({
          borderRadius: '40% 60% 42% 58% / 41% 51% 49% 59%',
        })
        await next({
          borderRadius: '33% 67% 70% 30% / 30% 30% 70% 70%',
        })
      }
    },
  })
  const secondBlob = useSpring({
    config: { duration: 900 },
    from: {
      borderRadius: '33% 67% 70% 30% / 30% 30% 70% 70%',
    },
    to: async next => {
      while (isSecondHovered) {
        await next({
          borderRadius: '60% 40% 54% 46% / 40% 65% 35% 60%',
        })
        await next({
          borderRadius: '78% 22% 63% 37% / 34% 34% 66% 66%',
        })
        await next({
          borderRadius: '51% 49% 63% 37% / 56% 42% 58% 44%',
        })
        await next({
          borderRadius: '40% 60% 42% 58% / 41% 51% 49% 59%',
        })
        await next({
          borderRadius: '76% 24% 35% 65% / 45% 70% 30% 55% ',
        })
      }
    },
  })
  const thirdBlob = useSpring({
    config: { duration: 900 },
    from: {
      borderRadius: '51% 49% 63% 37% / 56% 42% 58% 44%',
    },
    to: async next => {
      while (isThirdHovered) {
        await next({
          borderRadius: '60% 40% 54% 46% / 40% 65% 35% 60%',
        })
        await next({
          borderRadius: '78% 22% 63% 37% / 34% 34% 66% 66%',
        })
        await next({
          borderRadius: '33% 67% 70% 30% / 30% 30% 70% 70%',
        })
        await next({
          borderRadius: '40% 60% 42% 58% / 41% 51% 49% 59%',
        })
        await next({
          borderRadius: '76% 24% 35% 65% / 45% 70% 30% 55% ',
        })
      }
    },
  })

  return (
    <>
      <Box width="100%" pl={[3, 5]} pr={[2, 5]} maxWidth="500px" mx="auto">
        <Box
          display="grid"
          width="100%"
          gridTemplateRows="repeat(3, 1fr)"
          gridTemplateColumns={['repeat(3, 1fr)', 'repeat(4, 1fr)']}
          gridTemplateAreas={[
            "'first first ...' '... second second' 'third third ...'",
            "'first first ... ...' '... ... second second' '... third third ...'",
          ]}
        >
          <Item gridArea="first">
            <Text
              to="/expertise/trainings"
              onMouseEnter={() => setFirstHovered(true)}
              onMouseLeave={() => setFirstHovered(false)}
            >
              <AnimatedBlobb style={firstBlob} />
              Leadership
              <Br />
              Trainings
            </Text>
          </Item>
          <Item gridArea="second" justifyContent="flex-end">
            <Text
              to="/expertise/keynotes"
              onMouseEnter={() => setSecondHovered(true)}
              onMouseLeave={() => setSecondHovered(false)}
            >
              <AnimatedBlobb style={secondBlob} />
              Keynotes
            </Text>
          </Item>
          <Item gridArea="third">
            <Text
              to="/expertise/retreats"
              onMouseEnter={() => setThirdHovered(true)}
              onMouseLeave={() => setThirdHovered(false)}
            >
              <AnimatedBlobb style={thirdBlob} />
              Leadership
              <Br />
              Retreats
            </Text>
          </Item>
        </Box>
      </Box>
    </>
  )
}

export { ExpertiseOverview }
